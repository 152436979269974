import { customerTypes } from './customer.types';

const INITIAL_STATE = {
  loading: false,
  customer: null,
  selectedSalesMan: null,
  currency: '$',
  accountUrl: '',
  accountId: '',
  error: null,
  uploadedLogo: null,
  exceededTrailLimit: false
};

const customerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case customerTypes.SET_LOADING_CUSTOMER:
      return {
        ...state,
        loading: payload,
      };
    case customerTypes.SET_CUSTOMER_CURRENCY:
      return {
        ...state,
        currency: payload,
      };
    case customerTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        selectedSalesMan: payload.settings?.managerSelectOptions?.salesmenSelected || [],
        loading: false,
        error: null
      };
    case customerTypes.SET_SELECTED_SALESMAN:
      return {
        ...state,
        selectedSalesMan: payload
      };
    case customerTypes.SET_ACCOUNT_ID:
      return {
        ...state,
        loading: false,
        accountId: payload,
        error: null
      };
    case customerTypes.SET_ACCOUNT_URL:
      return {
        ...state,
        loading: false,
        accountUrl: payload,
        error: null
      };
    case customerTypes.SET_INITIAL_STATE:
      return {
        token: '',
        user: null,
        customer: null,
      };
    case customerTypes.SET_ERROR_CUSTOMER:
      return {
        ...state,
        loading: false,
        error: payload
      };
      
      case customerTypes.SET_COMPANY_LOGO:
        return {
          ...state,
          uploadedLogo: payload,
          error: null
        };
      case customerTypes.SET_IS_EXCEEDED_TRIAL_LIMIT:
        return {
          ...state,
          exceededTrailLimit: payload,
          loading: false,
        };
    default:
      return state;
  }
};

export default customerReducer;
