export const widgetTypes = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_SHOW_STEP: 'SET_SHOW_STEP',
  SET_ORDER_TYPE: 'SET_ORDER_TYPE',
  SET_WIDGET_INITIAL_STATE: 'SET_WIDGET_INITIAL_STATE',
  SET_QUANTITY_FOR_SERVICE: 'SET_QUANTITY_FOR_SERVICE',
  SET_PACKAGES: 'SET_PACKAGES',
  SET_SERVICES: 'SET_SERVICES',
  SET_CONTRACTOR: 'SET_CONTRACTOR',
  SET_WIDGET_LOADING: 'SET_WIDGET_LOADING',
  UPDATE_MEASUREMENT: 'UPDATE_MEASUREMENT',
  UPDATE_SERVICE_QUANTITY: 'UPDATE_SERVICE_QUANTITY',
  UPDATE_WIDGET_SERVICE_QUANTITY_IN_CART: 'UPDATE_WIDGET_SERVICE_QUANTITY_IN_CART',
  UPDATE_PACKAGES_QUANTITY: 'UPDATE_PACKAGES_QUANTITY',
  TOGGLE_SERVICE_IN_CART: 'TOGGLE_SERVICE_IN_CART',
  SET_CART: 'SET_CART',
  TOGGLE_PACKAGE_IN_CART: 'TOGGLE_PACKAGE_IN_CART',
  CHANGE_PAYMENT_TYPE: 'CHANGE_PAYMENT_TYPE',
  UPDATE_ORDER_USER: 'UPDATE_ORDER_USER',
  SET_ERRORS: 'SET_ERRORS',
  SHOULD_SHOW_IFRAME: 'SHOULD_SHOW_IFRAME',
  SET_TOTAL_STEPS: 'SET_TOTAL_STEPS',
  SET_WIDGET_ERROR: 'SET_WIDGET_ERROR',
  SET_SIGNATURE: 'SET_SIGNATURE',
  SET_CONTACT_INFO: 'SET_CONTACT_INFO',
  SET_ORDER_PDF_BASE_64: 'SET_ORDER_PDF_BASE_64',
  SET_WIDGET_BOUNDARY: 'SET_WIDGET_BOUNDARY',
  SET_PAYPAL_PAID: 'SET_PAYPAL_PAID',
  SET_VIEW_OTHER_SERVICES: 'SET_VIEW_OTHER_SERVICES',
  SET_ORDER_DISCOUNTS: 'SET_ORDER_DISCOUNTS',
  SET_PAYMENT_DISCOUNTS: 'SET_PAYMENT_DISCOUNTS',
  SET_ORDER_QUESTIONS: 'SET_ORDER_QUESTIONS',
  SET_IS_ACCEPTED_TERMS: 'SET_IS_ACCEPTED_TERMS',
  SET_CUSTOM_QUOTES: 'SET_CUSTOM_QUOTES',
  TOOGLE_SERVICE_IN_CUSTOM_QUOTE: 'TOOGLE_SERVICE_IN_CUSTOM_QUOTE',
  TOOGLE_PACKAGE_IN_CUSTOM_QUOTE: 'TOOGLE_PACKAGE_IN_CUSTOM_QUOTE',
  TOOGLE_PACKAGE_IN_SERVICE_CUSTOM_QUOTE: 'TOOGLE_PACKAGE_IN_SERVICE_CUSTOM_QUOTE',
  SET_CUSTOM_DISCOUNTS: 'SET_CUSTOM_DISCOUNTS',
  SET_CALL_ON_PLACE_SELECTED: 'SET_CALL_ON_PLACE_SELECTED',
  SET_CURRENT_REGION: 'SET_CURRENT_REGION',
  TOGGLE_CATEGORY: 'TOGGLE_CATEGORY',
  TOGGLE_SUB_CATEGORY: 'TOGGLE_SUB_CATEGORY',
  SET_SUB_STEP: 'SET_SUB_STEP',
  SET_TOTAL_SUB_STEPS: 'SET_TOTAL_SUB_STEPS',
  UPDATE_PACKAGE_IN_CART: 'UPDATE_PACKAGE_IN_CART',
  SET_WIDGET_STEPPER: 'SET_WIDGET_STEPPER',
  SET_SEARCHED_SERVICES_TYPE: 'SET_SEARCHED_SERVICES_TYPE',
  SET_PRO_SEARCHED_FEATURES:'SET_PRO_SEARCHED_FEATURES',
  UPDATE_SEARCH_OPTIONS_DISPLAY_STATUS: 'UPDATE_SEARCH_OPTIONS_DISPLAY_STATUS',
  SET_PCI_VAULT_CARD_INFO: 'SET_PCI_VAULT_CARD_INFO',
  SET_PCI_VAULT_CARD_TOKEN: 'SET_PCI_VAULT_CARD_TOKEN',
  SET_REFERRER_INFO: 'SET_REFERRER_INFO',
  SET_ESTIMATE_DETAIL_IMAGES: 'SET_ESTIMATE_DETAIL_IMAGES',
  CLEAR_WIDGET_STATE: 'CLEAR_WIDGET_STATE',
  SET_NOTES:'SET_NOTES',
  SET_INTERNAL_TAGS:'SET_INTERNAL_TAGS',
  SET_YARD_INFO: 'SET_YARD_INFO'
};