import axios from 'axios';
import { saveAs } from 'file-saver';
import { EMERALD_TEST_ACCOUNT_CID } from '../../constants/app.constants';
import { orderTypeConst } from '../../constants/orderType.constants';
import { routeConstants } from '../../routes';
import { addQuantityToService } from '../../utils/addQuantityToService';
import { getUtmParams, shouldSendCallAhead } from '../../utils/app-utils';
import { applyServicesDiscountsOnPackage } from '../../utils/applyServicesDiscountsOnPackage';
import { calculateDiscount } from '../../utils/calculateDiscount';
import { getContractorEmail } from '../../utils/getBusinessEmail';
import { getPackagePrice } from '../../utils/getPackagePrice';
import { getServicePrice } from '../../utils/getServicePrice';
import { getServiceQuantity } from '../../utils/getServiceQuantity';
import { getServiceSqFeet } from '../../utils/getServiceSqFeet';
import sendRequest from '../../utils/useAxios';
import { validateOrder } from '../../utils/validateOrder';
import { GET_PUBLIC_CUSTOMER } from '../customer/customer.api';
import { hasCustomerEnabledServiceAutopilot } from '../customer/customer.selectors';
import { GET_PACKAGES, GET_PACKAGE_BY_ID } from '../packages/packages.api';
import { GET_REAL_GREEN_SERVICES_IN_PACKAGE, SEND_REAL_GREEN_DOCUMENT, SEND_REAL_GREEN_FLAGS, UPDATE_REAL_GREEN_LEAD, UPDATE_REAL_GREEN_PACKAGE } from '../real-green/real-green.api';
import { returnRealGreenPackageStatus } from '../real-green/real-green.selectors';
import { GET_PUBLIC_SERVICES, GET_SERVICES, GET_SERVICES_BY_ID } from '../services/services.api';
import { CREATE_ORDER, UPDATE_ORDER } from './widget.api';
import { calculateNormalTotalWithoutTax, getCustomerRedirectURL, getNormalTotalPrice, isOnSearchPage, isOnWidgetPage } from './widget.selectors';
import { widgetTypes } from './widget.types';
import { checkPackageHasCustomQuote } from '../../utils/checkPackageHasCustomQuote';
import { checkHasCustomQuote } from '../../utils/checkHasCustomQuote';
import { abandonedCart } from '../leads/leads.actions';
import { TagType } from '../../components/branding/internal-tags';

export const setCurrentStep = (currentStep) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CURRENT_STEP,
    payload: currentStep,
  });
};
export const setWidgetStepper = (stepper) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_STEPPER,
    payload: stepper,
  });
};
export const setTotalSteps = (totalSteps) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_TOTAL_STEPS,
    payload: totalSteps,
  });
};
export const setSubStep = (subStep) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_SUB_STEP,
    payload: subStep,
  });
};
export const setTotalSubSteps = (totalSubSteps) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_TOTAL_SUB_STEPS,
    payload: totalSubSteps,
  });
};
export const setShowSteps = (status) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_SHOW_STEP,
    payload: status,
  });
};
export const setLoading = (type) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_LOADING,
    payload: type,
  });
};
export const getContractorPackages = () => async (dispatch) => {
  const { data } = await sendRequest(GET_PACKAGES.METHOD, GET_PACKAGES.PATH);

  dispatch({
    type: widgetTypes.SET_PACKAGES,
    payload: data,
  });
};

export const getContractorServices = () => async (dispatch) => {
  const { data } = await sendRequest(GET_SERVICES.METHOD, GET_SERVICES.PATH);
  dispatch(setQuantityForService(data));
  dispatch({
    type: widgetTypes.SET_SERVICES,
    payload: data,
  });
};

export const getPublicServices = (contractor) => async (dispatch) => {
  const { data } = await sendRequest(
    GET_PUBLIC_SERVICES.METHOD,
    `${GET_PUBLIC_SERVICES.PATH}/${contractor}?active=true`
  );
  dispatch({
    type: widgetTypes.SET_SERVICES,
    payload: data,
  });
  // dispatch(setQuantityForService(data))
};

export const setQuantityForService = (services) => async (dispatch) => {
  const quantity = {};
  services?.map((service) => {
    quantity[service._id] = service.quantity;
  });
  dispatch({
    type: widgetTypes.SET_QUANTITY_FOR_SERVICE,
    payload: quantity,
  });
};

export const setIsPaidWithPaypal = () => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_PAYPAL_PAID,
  });
};

const service_visibilty = (service, sqFeet) => {
  let new_service;
  if (service.ranges && service.ranges.length) {
    const filtered_ranges = service.ranges.filter(range => {
      if (range.from <= sqFeet && (range.to >= sqFeet || !range.to) && range.hideService) {
        return range
      }
    })
    if (filtered_ranges.length > 0) {
      new_service = { ...service, hide: true }
    } else {
      new_service = { ...service, hide: false }
    }
  } else {
    new_service = { ...service, hide: false }
  }
  return new_service;
}

export const filter_services = (services, sqFeet) => {
  let filtered_services = []
  services?.forEach(service => {
    const newService = service_visibilty(service, sqFeet)
    if (!newService.hide) {
      filtered_services.push(newService)
    }
  })
  return filtered_services;
}

export const filter_packages = (packages, sqFeet) => {
  let filtered_packages = []
  packages?.forEach(pckg => {
    let newPackage;
    if (pckg.services && pckg.services.length) {
      let filtered_services = []
      pckg.services.forEach(service => {
        const newService = service_visibilty(service, sqFeet)
        if (newService.hide) {
          filtered_services.push(newService)
        }
      })
      if (filtered_services.length) {
        newPackage = { ...pckg, hide: true }
      } else {
        newPackage = { ...pckg, hide: false }
      }
    } else {
      newPackage = { ...pckg, hide: false }
    }
    if (!newPackage.hide) {
      filtered_packages.push(newPackage)
    }
  })
  return filtered_packages;
}

export const getPackagesById = (id, active, sqFeet, selectedCategories, selectedSubCategories, matchAnyOne, currentRegion) => async (dispatch) => {
  const categories = selectedCategories.length > 0 ? `&categories=${JSON.stringify(selectedCategories)}` : ''
  const subCategories = selectedSubCategories.length > 0 ? `&subCategories=${JSON.stringify(selectedSubCategories)}` : ''
  const matchBoth = matchAnyOne ? '' : '&matchBoth=true'
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    GET_PACKAGE_BY_ID.METHOD,
    `${GET_PACKAGE_BY_ID.PATH}/${id}?active=${active}&regions=${[currentRegion?._id]}&withoutRegions=true${categories}${subCategories}${matchBoth}`
  );
  // filter packages to hide
  const filtered_packages = filter_packages(data, sqFeet)
  dispatch(setSuccess(widgetTypes.SET_PACKAGES, filtered_packages));
  dispatch(setLoading(false));
};

export const getServicesById = (id, active, sqFeet, selectedCategories, selectedSubCategories, matchAnyOne, currentRegion, ) => async (dispatch) => {
  const categories = selectedCategories.length > 0 ? `&categories=${JSON.stringify(selectedCategories)}` : ''
  const subCategories = selectedSubCategories.length > 0 ? `&subCategories=${JSON.stringify(selectedSubCategories)}` : ''
  const matchBoth = matchAnyOne ? '' : '&matchBoth=true'
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    GET_SERVICES_BY_ID.METHOD,
    `${GET_SERVICES_BY_ID.PATH}/${id}?active=${active}&regions=${[currentRegion?._id]}&withoutRegions=true${categories}${subCategories}${matchBoth}`
  );
  // filter services to hide
  let filtered_services = filter_services(data, sqFeet)
  dispatch(setSuccess(widgetTypes.SET_SERVICES, filtered_services));
  dispatch(setLoading(false));
};

export const setMeasurement = (updatedProperty) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_MEASUREMENT,
    payload: updatedProperty,
  });
};
export const updateServiceQuantity = (updatedService) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_SERVICE_QUANTITY,
    payload: updatedService,
  });
};
export const updateWidgetServiceQuantityInCart = (updatedService) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_WIDGET_SERVICE_QUANTITY_IN_CART,
    payload: updatedService,
  });
};
export const toggleServiceInCart = (service) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_SERVICE_IN_CART,
    payload: service,
  });
};
export const togglePackageInCart = (packageItem) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_PACKAGE_IN_CART,
    payload: packageItem,
  });
};
export const UpdateCartPackage = (packageItem) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_PACKAGE_IN_CART,
    payload: packageItem,
  });
};
export const toggleCategories = (categories) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_CATEGORY,
    payload: categories,
  });
};
export const toggleSubCategories = (subCategories) => async (dispatch) => {
  dispatch({
    type: widgetTypes.TOGGLE_SUB_CATEGORY,
    payload: subCategories,
  });
};
export const setCart = (services, packages) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CART,
    payload: { services, packages },
  });
};
export const changePaymentType = (paymentType) => async (dispatch) => {
  dispatch({
    type: widgetTypes.CHANGE_PAYMENT_TYPE,
    payload: paymentType,
  });
};
export const updateOrderUser = (orderUser) => async (dispatch) => {
  dispatch({
    type: widgetTypes.UPDATE_ORDER_USER,
    payload: orderUser,
  });
};

export const setContactInfo = (contactInfo) => async (dispatch) => {
  contactInfo = { ...contactInfo };
  dispatch({
    type: widgetTypes.SET_CONTACT_INFO,
    payload: contactInfo,
  });
};

export const updateShowIFrame = (payload) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SHOULD_SHOW_IFRAME,
    payload,
  });
};

export const setViewOtherServices = (payload) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SET_VIEW_OTHER_SERVICES,
    payload,
  });
};

export const resetWidget = () => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_WIDGET_INITIAL_STATE,
  });
};
export const setErrors = (errors) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_ERRORS,
    payload: errors,
  });
};

export const getOrderDiscountIds = (orderDiscounts) => {
  return orderDiscounts.map(orderDiscount => orderDiscount._id);
}

export const createOrder = (orderType, router, fieldRoutesCreateOrder?, fieldRoutesCreditCardState?) => async (dispatch, getState) => {
  const state = getState();
  const {
    orderUser,
    cart,
    contractor: contractorRedux,
    orderFrom,
    quantity,
    signature,
    orderbase64,
    boundary,
    orderQuestions,
    customQuotes,
    customDiscounts,
    orderDiscounts,
    searchedServicesType,
    pciVault_card,
    referrerInfo,
    yardInfo,
    estimateDetail_images,
    notes,
    currentRegion
  } = state.widget;
  const { member: salesPerson } = state.teamMember

  const { lead, fieldRoutesSubscriptionIDs } = state.leads;
  const { searchesLog } = state.searchesLog;
  const { currentleadData, selectedItems ,selectedIds,allPackages} = state.realGreen
  const {allIntegrations}=state.integrations
  const { user, member } = state.user;
  const { allEnabled,tempData } = state.integrations
  
  const contractor = isOnSearchPage(router) ? user : contractorRedux
  const { data: customer } = await sendRequest(
    GET_PUBLIC_CUSTOMER.METHOD,
    `${GET_PUBLIC_CUSTOMER.PATH}/${contractor?._id}`
  );

  const { tax, measurement, sqFeet,place } = state.measurement;
  const allCartState = state.widget.cart
  const totalPrice =
    customer?.settings?.enabledTax === false
      ? calculateNormalTotalWithoutTax()(state)
      : getNormalTotalPrice()(state);

  let services = {};
  let onePackage = [];
  let packages = [];
  cart.packages.forEach(function (packageItem) {
    services = packageItem.services.map((serviceItem) => ({
      ...serviceItem,
      quantity: packageItem.quantity[serviceItem._id],
    }));
    onePackage = { ...packageItem, services };
    packages = [...packages, onePackage];
  });

  let combinedNotes: string[]=[]
  let combinedImages: string[]=[]
  if(lead?.notes?.length){
    //Adding lead notes
    combinedNotes.push(...lead?.notes)
  }
  if(lead?.uploadedImages?.length){
    //Adding Lead Images
    combinedImages.push(...lead?.uploadedImages)
  }
  if(notes?.length){
    combinedNotes.push(...notes)
  }
  if(estimateDetail_images?.length){
    combinedImages.push(...estimateDetail_images)
  }

  const utmParams = getUtmParams(router);

  let order = {
    user: orderUser,
    amount: totalPrice,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map((orderPackage) => orderPackage._id),
    measurement,
    boundarySqFeet: boundary?.acreage_calc * 43560,
    payment: orderType,
    contractor: contractor._id,
    squareFeet: sqFeet,
    tax,
    customQuotes,
    customDiscounts,
    widgetContractor: {
      name: contractor.name,
      email: getContractorEmail(customer?.businessEmail, contractor.email),
      companyName: contractor.company,
      companyLogo: customer.customerLogo,
    },
    signature: signature,
    orderQuestions: isOnSearchPage(router) ? [] : orderQuestions,
    discounts: orderDiscounts ? getOrderDiscountIds(orderDiscounts) : null,
    searcheslogs: searchesLog?._id,
    orderServiceType: searchedServicesType,
    pciVault_card,
    referrerInfo,
    yardInfo,
    utmParameter: router?.query?.utm || '',
    sourceUtm:router?.query?.source || '',
    currentActiveUser: member || contractor,
    fieldRoutesCreateOrder,
    fieldRoutesCustomerID: lead?.fieldRoutesIntegration?.contactID,
    fieldRoutesSubscriptionIDs,
    fieldRoutesCreditCardState,
    notes: Array.from(new Set(combinedNotes)),
    uploadedImages: Array.from(new Set(combinedImages)),
    campaignId: router?.query?.campaignId || "",
    serviceAreaRegion: currentRegion?._id,
    utmParameters: utmParams,
    hubspotIntegration: { contactId: lead?.hubspotIntegration?.contactId }
  };
  const { isValid, errors } = validateOrder(order, customer, isOnSearchPage(router) ? [] : orderQuestions);
  if (!isValid) {
    dispatch(setErrors(errors));
    dispatch(setLoading(false));
    return;
  }
  if(allEnabled?.includes('clover') ){
    const address = place?.formatted_address?.split(',').map(item => item.trim());
    const state = address[2].split(' ');
    const requestBody={
      "account": tempData?.clover?.account,
      "expiry": tempData?.clover?.expiry, // 12/26
      "cvv2": tempData?.clover?.card_cvv,
      "amount": totalPrice,
      "currency": customer?.countrySettings?.country === 'Canada' ? 'CAD' : 'USD',
      "name":orderUser?.name || '',
      "address": address[0],
      "city": address[1],
      "region": state[0],
      "country": address?.at(-1) || 'USA',
      "postal": state[1],
      "email": orderUser?.email || '',
      "phone": orderUser?.phone || '',
    }

    if (orderType == orderTypeConst.PAY_NOW) {
      const { data } = await sendRequest('POST', '/orders/clover-payment', { customerId: customer?._id, requestBody })
      if (!data || data?.statusCode) {
        dispatch(setErrors([data?.message]));
        dispatch(setLoading(false));
        return;
      }
    }

    if ((orderType == orderTypeConst.PAY_AS_YOU_GO && !hasCustomerEnabledServiceAutopilot(customer)) || ["MONTHLY_PAYMENT", "EZ_PAY"].includes(orderType)) {
      const { data } = await sendRequest('POST', '/orders/clover-payment-profile', { customerId: customer?._id, requestBody })
      if (!data || data?.statusCode) {
        dispatch(setErrors([data?.message]));
        dispatch(setLoading(false));
        return;
      }
    }

  }

  if (allEnabled?.includes('clover-payment')) {
    if(!tempData?.clover_payment?.card_number || !tempData?.clover_payment?.card_expiry || !tempData?.clover_payment?.card_cvv || !tempData?.clover_payment?.brand){
      dispatch(setErrors(['Please provide the required card information']));
      dispatch(setLoading(false));
      return
    }


    const { data: customerOrder } = await sendRequest('POST', '/orders/clover-order', {order, customerId:customer?._id, isTaxEnable: customer?.settings?.enabledTax || false})
    if(customerOrder.statusCode){
      dispatch(setErrors([customerOrder?.message]));
      dispatch(setLoading(false));
      return;
    }
    const { cloverOrderId } = customerOrder
    const requestBody = {
      customerId: customer?._id,
      amount: totalPrice,
      card_number: tempData?.clover_payment?.card_number,
      card_expiry: tempData?.clover_payment?.card_expiry,
      card_cvv: tempData?.clover_payment?.card_cvv,
      brand: tempData?.clover_payment?.brand,
      cloverOrderId
    }
    const { data } = await sendRequest('POST', '/orders/clover-order-payment', requestBody)
    if (data?.statusCode) {
      dispatch(setErrors([data?.message]));
      dispatch(setLoading(false));
      return;
    }
  }
  // clover pay as you go => !hasCustomerEnabledServiceAutopilot(customer)
  let check = true
  if (customer?._id == EMERALD_TEST_ACCOUNT_CID) {
    orderQuestions?.map(singleQuestion => {
      if (singleQuestion.question.includes('locked gate') || singleQuestion.question.includes('dogs')) {
        if (singleQuestion.answer.includes('Yes')) check = false
      }
    })
  }

  // dispatch(setLoading(false));
  if (orderFrom == 'BUY_FROM_QUOTE') {

    //send flags to realGreen
    if (allEnabled.includes('realGreen') && check) {
      const res = await sendFlagsToRealGreen(customer, orderQuestions, currentleadData?.customerNumber)
    }
    let isUpdateCallCode=shouldSendCallAhead(orderQuestions)
    //perform updates
    // allEnabled.includes('realGreen') && await checkUpdateRealGreenData(state,allPackages, salesPerson?.integrationsData?.realGreen?.realGreenUsername ||  allIntegrations?.realGreen?.auth?.realGreenUserName,customer, currentleadData?.customerData, sqFeet, selectedItems, selectedIds, cart, user, orderQuestions,isUpdateCallCode)
   
    order['_id'] = router.query.id;
    order['orderPdf'] = orderbase64;
    delete order?.fieldRoutesSubscriptionIDs
  
    const { data } = await sendRequest(UPDATE_ORDER.METHOD, UPDATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(typeof(data.message) === 'string' ? [data.message]: data.message));
      dispatch(setLoading(false));
      return;
    }

    //SendDocuments
    allEnabled.includes('realGreen') && await sendRealGreenDocuments( salesPerson?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,customer,currentleadData?.customerNumber, data?.orderPdf, user)
    // dispatch(setLoading(false));

    router.push(getCustomerRedirectURL(customer, orderType))
    // router.push((!customer?.serviceAutoPilotEnabled && customer?.checkoutUrl) ? customer?.checkoutUrl : '/widget/thank-you');
  } else {
    //send flags to realGreen
    if (allEnabled.includes('realGreen') && check) {
      const res = await sendFlagsToRealGreen(customer, orderQuestions, currentleadData?.customerNumber)
    }
    let isUpdateCallCode=shouldSendCallAhead(orderQuestions)

     //perform updates
    // allEnabled.includes('realGreen') && await checkUpdateRealGreenData(state, allPackages,salesPerson?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,customer, currentleadData?.customerData, sqFeet, selectedItems, selectedIds, cart, user, orderQuestions,isUpdateCallCode)
    const leadId = lead?._id;
    let leadSessions = []
    if (lead?.leadSessions) {
      leadSessions = lead.leadSessions
      leadSessions[leadSessions.length - 1]['Order_placed'] = Date.now()
    }
    window.removeEventListener('beforeunload',dispatch(abandonedCart('order')))
    const { data } = await sendRequest(CREATE_ORDER.METHOD, CREATE_ORDER.PATH, {
      ...order,
      leadId,
      leadSessions,
    });

    //for density blaster notification
    if (router?.query?.campaignId) {
      const body = {
        type: "ORDER",
        placeId: router?.query?.placeId,
        campaignId: router?.query?.campaignId,
        address: router?.query?.address,
        isChecked: false
      }

      createDensityBlasterCampaignFeed(body)
    }

    if (data.statusCode) {
      dispatch(setErrors(typeof(data.message) === 'string' ? [data.message]: data.message));
      dispatch(setLoading(false));
      return;
    }

    //SendDocuments
    allEnabled.includes('realGreen') && await sendRealGreenDocuments( salesPerson?.integrationsData?.realGreen?.realGreenUsername || allIntegrations?.realGreen?.auth?.realGreenUserName,customer,currentleadData?.customerNumber, data?.orderPdf, user)
    // dispatch(setLoading(false));

    isOnWidgetPage(router)
      ? window.parent.postMessage(
          { type: 'redirect', url: getCustomerRedirectURL(customer, orderType) },
          '*'
        )
      : fieldRoutesCreateOrder && isOnSearchPage(router)
      ? router.push(routeConstants.DASHBOARD.pathname)
      : router.push(getCustomerRedirectURL(customer, orderType));

  }
};

async function sendRealGreenDocuments(realGreenUserName,customer,realGreenCustomerId, documentUrl, user) {
  //send pdf
  const docRequestBody = {
    "cust_no": realGreenCustomerId,
    // "fileName": "https://lawn-technologies-images-dev.s3.us-east-2.amazonaws.com/6e0c08e6-57d0-4894-a4a1-2c588fb2946d.pdf",
    "fileName": documentUrl,
    "emp_Id": user?.integrationsData?.realGreen?.realGreenUsername || realGreenUserName,
    "category": 1,
    "enterDate": new Date().toISOString(),
    "description": "Order quote PDF"
  }
  const { data: pdfData } = await sendRequest(SEND_REAL_GREEN_DOCUMENT.METHOD, SEND_REAL_GREEN_DOCUMENT.PATH, { customerId: customer?._id, requestBody: docRequestBody })

}

async function checkUpdateRealGreenData(state, allPackages,realGreenUserName,customer, realGreenCustomerData, size, selectedItems,selectedIds,cart, user, orderQuestions,isUpdateCallCode?) {

  const { member: salesPerson } = state.teamMember
  const{boundary, quantity} = state.widget
  const {sqFeet, measurement, polygonsFeaturesSqFeet} = state.measurement

  let frontyardType = null
  let backyardType = null
  orderQuestions?.map((each) => {
    if (each?.realGreenFlags) {
      if (each?.question === "What is your front yard grasstype?") {
        frontyardType = each?.answer[0]
      }
      if (each?.question === "What is your back yard grasstype?") {
        backyardType = each?.answer[0]
      }
    }
  })

  const updatedCustomerData = {
    ...realGreenCustomerData,
    size: size / 1000 || realGreenCustomerData?.size,
    statusCharacter: customer?._id==EMERALD_TEST_ACCOUNT_CID?"3":"9",
    ...(isUpdateCallCode && {callCode: 9})
    // techNote: `Property Size = ${size} sqFeet\r\nFront Yard Turf Type: ${frontyardType}\r\nBack Yard Turf Type: ${backyardType}${realGreenCustomerData?.techNote}\r\n`
  }
  const requestBody = updatedCustomerData

  const { data } = await sendRequest(UPDATE_REAL_GREEN_LEAD.METHOD, UPDATE_REAL_GREEN_LEAD.PATH, { customerId: customer?._id, requestBody, memberId: salesPerson?.integrationsData?.realGreen?.realGreenUsername || user?.integrationsData?.realGreen?.realGreenUsername || ''})

  const allCartItems = [...cart?.services, ...cart?.packages]
  const newItemsToUpdate = []

  allCartItems.map((eachCartItem) => {
    if (eachCartItem?.realGreenItem && eachCartItem?.realGreenItem?.programDefinitionID) {
      const temp_selected_package = selectedItems.find(item => item?.programCodeId === eachCartItem?.realGreenItem?.programDefinitionID)
      if (temp_selected_package) {
        let isCustomQuote = false
        if(eachCartItem?.services?.length){
          isCustomQuote = checkPackageHasCustomQuote(eachCartItem, boundary?.acreage_calc*43560, sqFeet, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
        } else {
          isCustomQuote = checkHasCustomQuote(eachCartItem, quantity, boundary, sqFeet, measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0, polygonsFeaturesSqFeet)
        }
        newItemsToUpdate.push({...temp_selected_package, isCustomQuote})
      }
    }
  })
  for (let eachItem of newItemsToUpdate) {
    //GET REAL GREEN SERVICES IN PROGRAMS
    const { data:servicesInPackage } = await sendRequest(GET_REAL_GREEN_SERVICES_IN_PACKAGE.METHOD, GET_REAL_GREEN_SERVICES_IN_PACKAGE.PATH, { customerId: customer?._id, requestBody:{
      customerNumber: realGreenCustomerData?.id, programId: eachItem?.id
    } })
    let customizedData: any = eachItem
    if (servicesInPackage?.length > 0) {
      //If is a program having services
      const updatedServicesArray = servicesInPackage?.map((eachService: any) => {
        eachService.size = (size / 1000) || realGreenCustomerData?.size
        return eachService
      })
      customizedData["services"] = updatedServicesArray
    }
    const programType=allPackages?.find((eachOriginal:any)=>eachOriginal?.programDefinitionID==eachItem?.programCodeId)?.programType
    let updatedPackageData = {
      ...customizedData,
      status: (eachItem?.isCustomQuote && customer?._id == EMERALD_TEST_ACCOUNT_CID) ? '0' : returnRealGreenPackageStatus(customer?._id,programType,false),
      size: (size / 1000) || realGreenCustomerData?.size,
      soldby1: user?.integrationsData?.realGreen?.realGreenUsername || realGreenUserName,
      dateSold: new Date().toISOString()
    }
    const requestBody = updatedPackageData

    const { data } = await sendRequest(UPDATE_REAL_GREEN_PACKAGE.METHOD, UPDATE_REAL_GREEN_PACKAGE.PATH, { customerId: customer?._id, requestBody })
    
  }
}

const sendFlagsToRealGreen = async (customer: any, orderQuestions: any, realGreenCustomerId: any) => {
  //send to realGreen
  let flagsToSend = []
  orderQuestions?.map((each) => {
    if (each?.realGreenFlags) {
      if (each?.realGreenFlags[each?.answer[0]]) {
        flagsToSend.push(each?.realGreenFlags[each?.answer[0]])
      }
    }
  })
  if (flagsToSend.length === 0) {
    return
  }

  let requestBody = { flagsToSend, realGreenCustomerId }

  const { data } = await sendRequest(SEND_REAL_GREEN_FLAGS.METHOD, SEND_REAL_GREEN_FLAGS.PATH, { customerId: customer?._id, requestBody });
  // if (data.statusCode) {
  //   dispatch(setErrors(typeof(data.message) === 'string' ? [data.message]: data.message));
  //   dispatch(setLoading(false));
  //   return;
  // }
  if(flagsToSend.includes(13)||flagsToSend.includes(84)){
    return true
  }
}

export const createOrderLocalStorage = (orderData, orderType, router) => async (dispatch) => {
  const { widget, measurement: measurementData, totalPrice, _id, leads } = orderData;
  const {
    orderUser,
    cart,
    contractor,
    orderFrom,
    quantity,
    signature,
    boundary,
    orderQuestions,
    customQuotes,
    customDiscounts,
    paymentType,
    orderDiscounts,
    searchedServicesType,
    pciVault_card,
    referrerInfo,
    currentRegion
  } = widget;
  const { lead } = leads

  const { data: customer } = await sendRequest(
    GET_PUBLIC_CUSTOMER.METHOD,
    `${GET_PUBLIC_CUSTOMER.PATH}/${contractor._id}`
  );

  const { tax, measurement, sqFeet } = measurementData;
  let services = {};
  let onePackage = [];
  let packages = [];
  cart.packages.forEach(function (packageItem) {
    services = packageItem.services.map((serviceItem) => ({
      ...serviceItem,
      quantity: packageItem.quantity[serviceItem._id],
    }));
    onePackage = { ...packageItem, services };
    packages = [...packages, onePackage];
  });

  let order = {
    user: orderUser,
    amount: totalPrice,
    services: addQuantityToService(cart.services, quantity),
    packages: packages?.map((orderPackage) => orderPackage._id),
    measurement,
    boundarySqFeet: boundary?.acreage_calc * 43560,
    payment: orderType,
    contractor: contractor._id,
    squareFeet: sqFeet,
    tax,
    signature,
    customQuotes,
    customDiscounts,
    widgetContractor: {
      name: contractor.name,
      email: getContractorEmail(customer?.businessEmail, contractor.email),
      companyName: contractor.company,
      companyLogo: customer.customerLogo,
    },
    orderQuestions,
    discounts: orderDiscounts ? getOrderDiscountIds(orderDiscounts) : null,
    orderServiceType: searchedServicesType,
    pciVault_card,
    referrerInfo,
    notes: lead?.notes || [],
    uploadedImages: lead?.uploadedImages || [],
    serviceAreaRegion: currentRegion?._id

  };
  const { isValid, errors } = validateOrder(order, customer, orderQuestions);

  // if(!isValid){
  //   dispatch(setErrors(errors))
  //   dispatch(setLoading(false));
  //   return
  // }
  if (orderFrom == 'BUY_FROM_QUOTE') {
    order['_id'] = _id;
    const { data } = await sendRequest(UPDATE_ORDER.METHOD, UPDATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(typeof(data.message) === 'string' ? [data.message]: data.message));
      dispatch(setLoading(false));
      return;
    }
  } else {
    const { data } = await sendRequest(CREATE_ORDER.METHOD, CREATE_ORDER.PATH, order);
    if (data.statusCode) {
      dispatch(setErrors(typeof(data.message) === 'string' ? [data.message]: data.message));
      dispatch(setLoading(false));
      return;
    }
  }

  localStorage.removeItem('ORDER_DATA');

  dispatch(setLoading(false));
  router.replace(getCustomerRedirectURL(customer, paymentType));
};

export const getBoundary = (placeId, requestedAddress) => async (dispatch) => {
  const { data } = await sendRequest('GET', `/boundary/${placeId}/${requestedAddress}`);
  if (data && !data.statusCode && data.boundary) {
    dispatch({
      type: widgetTypes.SET_WIDGET_BOUNDARY,
      payload: data.boundary,
    });
  }
};

export const getPaymentDiscounts = (customPaymentType) => async (dispatch, getState) => {
  const paymentTypeHasDiscount = getDiscounts(getState, customPaymentType);
  dispatch({
    type: widgetTypes.SET_PAYMENT_DISCOUNTS,
    payload: { type: customPaymentType, value: paymentTypeHasDiscount },
  });
};

const getDiscounts = (getState, customPaymentType?) => {
  const state = getState();
  const { cart, quantity, paymentType: cartPaymentType, boundary, customQuotes } = state.widget;
  const { sqFeet, measurement, polygonsFeaturesSqFeet } = state.measurement;
  let paymentTypeHasDiscount = false;
  cart.services?.forEach((servcie) => {
    const serviceDiscounts = getServiceDiscounts(
      servcie,
      quantity,
      customPaymentType || cartPaymentType,
      boundary,
      sqFeet,
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      polygonsFeaturesSqFeet,
    );
    if (serviceDiscounts) {
      paymentTypeHasDiscount = true;
    }
  });
  cart.packages?.forEach((packageItem) => {
    const packageDiscounts = getPackagesDiscounts(
      packageItem,
      customPaymentType || cartPaymentType,
      boundary,
      sqFeet,
      customQuotes,
      measurement?.propertyInfo?.propertyAttributes?.buildgSqFt || 0,
      polygonsFeaturesSqFeet,
    );
    if (packageDiscounts) {
      paymentTypeHasDiscount = true;
    }
  });
  return paymentTypeHasDiscount;
};

export const setCustomQutoes = (customQuotes) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_CUSTOM_QUOTES,
    payload: customQuotes,
  });
};

export const setCustomDiscounts = (customDiscounts, itemId, itemType, selectedDiscount) => (dispatch) => {

  let newDiscounts = {}
  const itemDiscounts = customDiscounts[itemType]

  if (!itemDiscounts[itemId]) {
    newDiscounts = { ...customDiscounts, [itemType]: { ...itemDiscounts, [itemId]: [selectedDiscount] } }

  } else if (itemDiscounts[itemId] && itemDiscounts[itemId].find(discount => discount._id === selectedDiscount._id)) {
    const singleItemDiscounts = itemDiscounts[itemId].filter(discount => discount._id !== selectedDiscount._id)
    newDiscounts = { ...customDiscounts, [itemType]: { ...itemDiscounts, [itemId]: singleItemDiscounts } }

  } else if (itemDiscounts[itemId] && !itemDiscounts[itemId].find(discount => discount._id === selectedDiscount._id)) {
    const singleItemDiscounts = itemDiscounts[itemId]
    singleItemDiscounts.push(selectedDiscount)
    newDiscounts = { ...customDiscounts, [itemType]: { ...itemDiscounts, [itemId]: singleItemDiscounts } }
  }

  dispatch({
    type: widgetTypes.SET_CUSTOM_DISCOUNTS,
    payload: newDiscounts
  })
}

const getServiceDiscounts = (service, quantity, cartPaymentType, boundary, sqFeet, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let squareFeet = getServiceSqFeet(service.areaType, sqFeet, (boundary.acreage_calc) * 43560, buildingSquareFeet, polygonsFeaturesSqFeet);

  let servicePrice = getServicePrice(
    service.pricePerSquare,
    getServiceQuantity(quantity, service._id),
    service.minPrice,
    service.fixedPrice,
    squareFeet,
    service.ranges,
    service.areaType
  );
  if (service.discount?.length) {
    const serviceQuantity = getServiceQuantity(quantity, service._id);
    const { discounts: serviceDiscounts } = calculateDiscount(
      service.discount,
      servicePrice,
      cartPaymentType,
      serviceQuantity
    );
    if (serviceDiscounts.length) {
      return serviceDiscounts;
    }
  }
  return null;
};

const getPackagesDiscounts = (packageItem, cartPaymentType, boundary, sqFeet, customQuotes, buildingSquareFeet, polygonsFeaturesSqFeet) => {
  let packagePrice = getPackagePrice(
    packageItem,
    sqFeet,
    boundary?.acreage_calc * 43560,
    customQuotes,
    buildingSquareFeet,
    polygonsFeaturesSqFeet,
  );
  if (packageItem.discount?.length) {
    const { discounts: packageDiscounts } = calculateDiscount(
      packageItem.discount,
      packagePrice,
      cartPaymentType
    );
    if (packageDiscounts.length) {
      return packageDiscounts;
    }
  }
  if (packageItem.servicesDiscounts) {
    const { discounts: packageServicesDiscounts } = applyServicesDiscountsOnPackage(
      packageItem,
      sqFeet,
      boundary,
      customQuotes,
      cartPaymentType,
      buildingSquareFeet,
      polygonsFeaturesSqFeet,
    );
    if (packageServicesDiscounts.length) {
      return packageServicesDiscounts;
    }
  }
  return null;
};

export const getOrderDiscounts = (discount, orderDiscounts) => {
  if (orderDiscounts && orderDiscounts.includes(discount)) {
    return orderDiscounts
  } else {
    if (orderDiscounts) {
      return [...orderDiscounts, discount]
    } else {
      return [discount]
    }
  }
}

export const setOrderDiscount = (discount) => async (dispatch, getState) => {
  const state = getState();
  const { orderDiscounts } = state.widget
  const discounts = getOrderDiscounts(discount, orderDiscounts)
  dispatch({
    type: widgetTypes.SET_ORDER_DISCOUNTS,
    payload: discounts,
  });
};

export const setOrderDiscounts = (discounts) => async (dispatch) => {
  dispatch({
    type: widgetTypes.SET_ORDER_DISCOUNTS,
    payload: discounts,
  });
};

export const setCurrentRegion = (region) => {
  return {
    type: widgetTypes.SET_CURRENT_REGION,
    payload: region,
  };
};

export const setSearchedServicesType = (type) => {
  return {
    type: widgetTypes.SET_SEARCHED_SERVICES_TYPE,
    payload: type,
  };
};
export const setProSearchedFeatures = (features) => {
  return {
    type: widgetTypes.SET_PRO_SEARCHED_FEATURES,
    payload: features,
  };
};

export const updateSearchOptionsDisplay = (value) => {
  return {
    type: widgetTypes.UPDATE_SEARCH_OPTIONS_DISPLAY_STATUS,
    payload: value,
  };
};

export const setSuccess = (action, payload) => {
  return {
    type: widgetTypes[action],
    payload,
  };
};

export const setError = (payload) => {
  return {
    type: widgetTypes.SET_WIDGET_ERROR,
    payload,
  };
};

export const saveMapImage = async (Center, polygons, zoom, type, address) => {
  const { data } = await sendRequest('POST', '/measurement/image', { Center, polygons, zoom, type })
  saveAs(data, `${address}.png`)
};

export const saveMapProImage = async (Center, polygons, zoom, type, address) => {
  const { data } = await sendRequest('POST', '/measurement/image-pro', { Center, polygons, zoom, type })
  saveAs(data, `${address}.png`)
};

export const setReferrerInfo = (referrer) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_REFERRER_INFO,
    payload: referrer,
  });
}
export const setEstimateDetailImages = (images) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_ESTIMATE_DETAIL_IMAGES,
    payload: images
  });
}
export const setNotes = (notes) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_NOTES,
    payload: notes
  });
}

export const setInternalTags = (tags: TagType[] | []) => (dispatch) => {
  dispatch({
    type: widgetTypes.SET_INTERNAL_TAGS,
    payload: tags
  });
}

export const createDensityBlasterCampaignFeed = async (body) => {
  await axios.post(`${process.env.NEXT_PUBLIC_DENSITY_BLASTER_API_URL}/campaign-feed`, body)
}
export const getSpentTransactionCredits = ( boundaryArea: any, selectedFeatures: any) => {

  let lotAcres = boundaryArea/43560
  let selectedFeaturesCount = selectedFeatures?.length
  let creditsToDeduct = 5

  if (lotAcres <= 20) {

    creditsToDeduct = 1.8 * lotAcres + 0.146 * lotAcres * selectedFeaturesCount
  }
  else if (lotAcres <= 50) {

    creditsToDeduct = 1.58 * lotAcres + 0.146 * lotAcres * selectedFeaturesCount
  }
  else if (lotAcres < 100) {
    creditsToDeduct = 1.35 * lotAcres + 0.146 * lotAcres * selectedFeaturesCount
  }
  else {
    creditsToDeduct = 1.35 * lotAcres + 0.124 * lotAcres * selectedFeaturesCount
  }

  if (creditsToDeduct < 5) {
    creditsToDeduct = 5
  }

  creditsToDeduct = Math.round(creditsToDeduct)
  console.log({lotAcres,creditsToDeduct})
  return creditsToDeduct
}

export const proSpentTransaction = async (customer: any, boundaryArea: any, selectedFeatures: any) => {
  const creditsToDeduct=getSpentTransactionCredits(boundaryArea,selectedFeatures)
  
  let transaction = {
    user: customer.user,
    customerId: customer?._id,
    spentCredits: creditsToDeduct,
    transactionType: 'Spent',
    newCredits: customer?.totalProCredits,
    type: 'pro-deeplawn'
  }
  await sendRequest('POST', '/credit-transaction  ', transaction)
}

export const updateQuoteMeasurement = async (
  quoteId:string,
  updatedMeasurement:any
) => {

  return await sendRequest('PATCH', `/orders/quote-measurement/${quoteId}`, updatedMeasurement);
};