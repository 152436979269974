import { CheckIcon } from '@heroicons/react/solid';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { PopupModal } from "react-calendly";
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/card/card.component';
import { AddButton } from '../../library/stories/Buttons/AddButton';
import { updateCustomerStep } from '../../redux/customer/customer.actions';
import { routeConstants } from '../../routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#28A662",
    color: "white",
    borderRounded:"5px"
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepLabel: {
    "& .MuiStepIcon-active": { color: "red" },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }  }
}));

function getSteps() {
  return [
    'Complete your Company Profile',
    'Schedule an Onboarding Meeting',
    "Define your Company's Service Area",
    'Add your first Service or Package',
    'Enable Payment Processing',
    'Place the Deep Lawn widget on your website',
    'Enable Commercial Measurement',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return { btnText: 'Complete Profile', btnUrl: `${routeConstants.MY_ACCOUNT.pathname}/#my-account` };
    case 1:
      return {
        btnText: 'Schedule',
        // btnUrl: routeConstants.SERVICE_AREA.pathname,
        // tutorials: [
        //   {
        //     url: 'https://www.youtube.com/watch?v=X5SvxXSX68c',
        //     label: 'Service Area Tutorial'
        //   }
        // ]
      };
    case 2:
      return {
        btnText: 'Service Area',
        btnUrl: routeConstants.SERVICE_AREA.pathname,
        tutorials: [
          {
            url: 'https://www.youtube.com/watch?v=X5SvxXSX68c',
            label: 'Service Area Tutorial'
          }
        ]
      };
    case 3:
      return { 
        btnText: 'Setup Services', 
        btnUrl: routeConstants.CREATE_SERVICE.pathname,
        tutorials: [
          {
            url: 'https://www.youtube.com/watch?v=kdgpe0Nb8N8',
            label: 'Services Tutorial'
          }, 
          {
            url: 'https://www.youtube.com/watch?v=XnuFeFbPBuw',
            label: 'Packages Tutorial'
          }
        ]
      };
    case 4:
      return { btnText: 'Setup Stripe', btnUrl: `${routeConstants.INTEGRATIONS.pathname}#integrations-page` };
    case 5:
      return { btnText: 'Add Widget to Your Site', btnUrl: routeConstants.INTEGRATIONS.pathname };
    case 6:
      return { btnText: 'Enable', btnUrl: `${routeConstants.INTEGRATIONS.pathname}#addon-page` };
    default:
      return 'Unknown step';
  }
}

const HomeStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { customer, loading } = useSelector((state: any) => state.customer);
  const steps = getSteps();
  const [isHover, setIsHover] = useState(false)
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }


const skipButtonStyle = {
  color:'#28A662', 
  textDecoration: 'underline', 
  backgroundColor:isHover ?  "#D2E9E9" : "#DFE6E2", 
  borderRadius:"10px"
}

  useEffect(() => {
    if (customer) {
      const { stepsCompleted } = customer;
      if (!stepsCompleted?.completedProfile) setActiveStep(0);
      else if (stepsCompleted?.onBoardingMeeting === false && stepsCompleted?.onBoardingMeeting !== undefined) setActiveStep(1);
      else if (!stepsCompleted?.addedServiceArea) setActiveStep(2);
      else if (!stepsCompleted?.addedService) setActiveStep(3);
      else if (!stepsCompleted?.connectedStripe) setActiveStep(4);
      else if (!stepsCompleted?.skipAddWidget) setActiveStep(5);
      else if (stepsCompleted?.enabledCommercialMeasurement === false) setActiveStep(6);
      else setActiveStep(7);
    }
  }, [customer]);

  const handleNext = (url: any) => {
    router.push(url);
  };

  const handleReset = () => {
    router.push(routeConstants.DASHBOARD.pathname);
  };
  const dispatch = useDispatch();
  const router = useRouter();

  const handleSkip = (index) => {
    if (index === 1) {
      dispatch(updateCustomerStep('onBoardingMeeting'));
    } else if (index === 4) {
      dispatch(updateCustomerStep('connectedStripe'));
    } else if (index === 5) {
      dispatch(updateCustomerStep('skipAddWidget'));
    } else if (index === 6) {
      dispatch(updateCustomerStep('enabledCommercialMeasurement'));
    }
  };

  // const [complete, setComplete] = useState(false)

  // console.log(complete);

  return (
    <div className="container" id="calendly">
      {/* <Head>
      <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
      </Head> */}
      <Card title="Finish your Deep Lawn Setup ..." classes="mb-5 text-2xl text-green-500 mt-5">
        <p className="mb-0 text-gray-600 ml-3 mt-5 text-left text-lg">
          Your ecommerce store is almost ready. Complete the remaining steps below 👇
        </p>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              {/* <StepLabel>{label}</StepLabel> */}
              <div className='flex flex-row space-x-2'>
                <div className='rounded-full bg-green-500 justify-center h-6 w-6 text-sm p-0.5'>{activeStep > index ? <CheckIcon  className='text-white h-[20px] w-[20px]'/> : <div className='text-white ml-1.5'>{index + 1}</div>}</div>
                <span className='text-sm font-medium mt-0.5'>{label}</span>
              </div>
              <StepContent>
                <div className={classes.actionsContainer}>
                  <div className='flex flex-row space-x-2'>
                    {/* <Button
                      variant="contained"
                      onClick={() => handleNext(getStepContent(index).btnUrl)}
                      className={`${classes.button}`}
                    >
                      {getStepContent(index).btnText}
                    </Button> */}

                    <AddButton
                      label={getStepContent(index).btnText}
                      onClick={() => {
                        if (index === 1) {
                          const script = document.createElement('script');
                          // script.src = 'https://assets.calendly.com/assets/external/widget.js';
                          script.type = 'text/javascript';
                          script.async = true;
                          document.body.appendChild(script);
                          setIsCalendlyOpen(true);
                          // dispatch(updateCustomerStep('onBoardingMeeting'));
                        } else {
                          handleNext(getStepContent(index).btnUrl)
                        }
                      }}
                      primary
                      buttonStyles='uppercase'
                    />
                    {
                      getStepContent(index)?.tutorials && getStepContent(index)?.tutorials.map((tutorial) => (
                        <AddButton
                          label={tutorial.label}
                          href={tutorial.url}
                          primary
                          buttonStyles='uppercase'
                          target="_blank"
                        />
                      ))
                    }

                        <PopupModal
                          url="https://meetings.hubspot.com/rick-wall/deep_lawn_onboarding"
                          rootElement={document.getElementById("calendly")!}
                          onModalClose={() => {
                            dispatch(updateCustomerStep('onBoardingMeeting'));
                            setIsCalendlyOpen(false);
                          }}
                          open={isCalendlyOpen}
                        />

                    {(index === 1 || index === 4 || index === 5 || index === 6) && (
                      <Button
                        style={skipButtonStyle}
                        onClick={() => handleSkip(index)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Skip 
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button
              onClick={handleReset}
              className={classes.button}
              style={{ backgroundColor: '#000', color: '#fff' }}
            >
              Go to Home Page
            </Button>
          </Paper>
        )} */}
      </Card>
    </div>
  );
};

export default HomeStepper;
